import axios from '@axios'
import config from '../../config.json'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchGetDanhMuc(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.apiUrl.mainUrl}danh-muc/get-data`, queryParams, {
            headers: {
              'Content-Type': 'multipart/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    save(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.apiUrl.mainUrl}danh-muc/save`, userData, {
            headers: {
              'Content-Type': 'multipart/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    delete(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.apiUrl.mainUrl}danh-muc/delete`, params, {
            headers: {
              'Content-Type': 'multipart/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    loadModel(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.apiUrl.mainUrl}danh-muc/load`, params, {
            headers: {
              'Content-Type': 'multipart/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
